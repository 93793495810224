import React from "react";

export default function InspirationHome() {
  return (
    <>
      <div className="container-fluid px-5">
        <h2 className="title text-center my-5">INSPIRATIONS</h2>
        <div className="row">
          <div className="col-lg-5">
            <div className="position-relative mb-5">
              <img
                src="Assets/img/inspiration/wood-sideboard-red-living-room-with-copy-space.jpg"
                alt="Earthy Elegance"
                className="img-fluid"
                style={{ height: '380px', width: '100%' }}
                loading="lazy"  // Lazy loading
              />
              <p className="position-absolute bottom-0 end-0 inspiration_txt">
                <strong> BEAUTIFUL AND BOLD</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="position-relative earth_elegance">
              <img
                src="Assets/img/inspiration/cozy-living-room-with-wood-furniture-clay-colored-walls.webp"
                alt="Beautiful and Bold"
                className="img-fluid"
                style={{ height: '450px', width: '100%' }}
                loading="lazy"  // Lazy loading
              />
              <p className="position-absolute bottom-0 end-0 inspiration_txt">
                <strong> EARTHY ELEGANCE</strong>
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-5">
            <div className="position-relative">
              <img
                src="Assets/img/inspiration/illustration-living-room-interior.webp"
                alt="Earthy Elegance"
                className="img-fluid"
                style={{ height: '375px', width: '100%', marginTop: '-50px' }}
                loading="lazy"  // Lazy loading
              />
              <p className="position-absolute bottom-0 end-0 inspiration_txt">
                <strong> NATURAL NATURE</strong>
              </p>
            </div>
          </div>

          <div className="col mt-0">
            <div className="position-relative mt-4">
              <img
                src="Assets/img/inspiration/interior-decoration.webp"
                alt="Natural Nature"
                className="img-fluid"
                style={{ height: '300px', width: '100%' }}
                loading="lazy"  // Lazy loading
              />
              <p className="position-absolute bottom-0 end-0 inspiration_txt">
                <strong>VIBRANT VIBE</strong>
              </p>
            </div>
          </div>

          <div className="col">
            <div className="position-relative mt-4">
              <img
                src="Assets/img/inspiration/sofa-purple-living-room-with-copy-space.webp"
                alt="Vibrant Vibe"
                className="img-fluid"
                style={{ height: '300px', width: '100%' }}
                loading="lazy"  // Lazy loading
              />
              <p className="position-absolute bottom-0 end-0 inspiration_txt">
                <strong>PASTEL PALLETE</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
