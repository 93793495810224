// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.swiper {
    width: 100%;
    padding-top: 50px;
    /* padding-bottom: 50px; */
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 400px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/slider/styles.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,iBAAiB;IACjB,0BAA0B;EAC5B;;EAEA;IACE,2BAA2B;IAC3B,sBAAsB;IACtB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,cAAc;IACd,WAAW;EACb","sourcesContent":["\n.swiper {\n    width: 100%;\n    padding-top: 50px;\n    /* padding-bottom: 50px; */\n  }\n  \n  .swiper-slide {\n    background-position: center;\n    background-size: cover;\n    width: 300px;\n    height: 400px;\n  }\n  \n  .swiper-slide img {\n    display: block;\n    width: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
