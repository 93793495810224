import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import img1 from "../Assets/img/testimonials/client-testimonial.jpg";
import img2 from "../Assets/img/testimonials/istockphoto-1304857369-612x612.jpg";
import img3 from "../Assets/img/testimonials/istockphoto-1320811419-612x612.jpg";
import img4 from "../Assets/img/testimonials/istockphoto-1323400501-612x612.jpg";

// Dummy testimonial data
const testimonialData = [
    {
      id: 1,
      name: "Sidak Vats",
      text: "The service was exceptional, and the team went above and beyond to ensure my needs were met. I highly recommend them!",
      img: img1,
    },
    {
      id: 2,
      name: "Aarav Mehta",
      text: "Working with this team has been a fantastic experience. Their attention to detail and professionalism is unmatched.",
      img: img2,
    },
    {
      id: 3,
      name: "Priya Nair",
      text: "I’m so impressed with the quality of the service. They truly understand their clients' needs and deliver every time!",
      img: img3,
    },
    {
      id: 4,
      name: "Ananya Gupta",
      text: "From the first interaction to the final product, everything was handled flawlessly. I couldn’t have asked for a better experience!",
      img: img4,
    },
    {
      id: 5,
      name: "Rajesh Khanna",
      text: "Their innovative approach and commitment to excellence set them apart. I’ll definitely be returning for more projects.",
      img: img1,
    },
    {
      id: 6,
      name: "Neha Sharma",
      text: "A seamless experience from start to finish. Their customer support was especially helpful throughout the process.",
      img: img2,
    }
  
];

const TestimonialCarousel = () => {
  // Split the data into groups of two for each carousel item
  const groupedTestimonials = [];
  for (let i = 0; i < testimonialData.length; i += 2) {
    groupedTestimonials.push(testimonialData.slice(i, i + 2));
  }

  return (
    <div className="container py-5">
      <div className="text-center mb-4">
        <h2 className="fw-bold">Testimonials</h2>
        <p className="text-muted">
          Hear what our clients have to say about our services
        </p>
      </div>

      <Carousel indicators={false} interval={null} controls={true} nextLabel="" prevLabel="">
        {groupedTestimonials.map((group, index) => (
          <Carousel.Item key={index}>
            <div className="row justify-content-center">
              {group.map((testimonial) => (
                <div className="col-md-6" key={testimonial.id}>
                  <div className="card shadow-lg p-4 mb-4">
                    <div className="d-flex justify-content-center mb-3">
                      <img
                        src={testimonial.img}
                        alt={testimonial.name}
                        className="rounded-circle"
                        style={{ width: "150px", height: "150px", objectFit: "cover" }}
                      />
                    </div>
                    <div className="card-body text-center">
                      <p className="card-text text-muted">
                        "{testimonial.text}"
                      </p>
                      <h5 className="fw-bold mt-3">{testimonial.name}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Custom CSS to make arrows always visible */}
      <style>
        {`
          .carousel-control-prev,
          .carousel-control-next {
            filter: brightness(100%);
            opacity: 1;
            display: flex;
          }

          .carousel-control-prev-icon,
          .carousel-control-next-icon {
            background-color: black; /* Set a background for visibility */
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }
        `}
      </style>
    </div>
  );
};

export default TestimonialCarousel;
